import { useModalStore } from '@/store/zustand/useModalStore';
import { Button, Dropdown, Menu } from 'antd';
import { PenBoxIcon } from 'lucide-react';
import React from 'react';
import { getAiVersion } from '@/components/summary/SummaryResults';
import { connect } from 'react-redux';

function CreateDropdown({ user }) {
  const { setModal } = useModalStore();
  return (
    <Dropdown
      className="cursor-pointer"
      placement="bottomRight"
      trigger={['click']}
      overlay={(
        <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1] font-[Gordita-Regular]">
          <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={() => setModal('create_taxonomy_category')}>
            <span>Create Category</span>
          </Menu.Item>
          <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={() => setModal('create_taxonomy_topic')}>
            <span>Create Segment</span>
          </Menu.Item>
          {
            (getAiVersion() === 'v2' && user && user.email && (user.email.endsWith('@clientzen.io') || user.email.endsWith('@vertuoza.com'))) ? (
              <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={() => setModal('create_taxonomy_ai_topic')}>
                <span>Create AI Topic</span>
              </Menu.Item>
            ) : null
          }
        </Menu>
        )}
    >
      <Button
        className="flex items-center justify-center"
        icon={<PenBoxIcon size={16} />}
        type="primary"
      />
    </Dropdown>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(CreateDropdown);
