import React, { useState } from 'react';
import Card from '@/components/card';
import { cn } from '@/helpers/util';
import { Link } from 'react-router-dom';
import { Button, List } from 'antd';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useDashboard } from '@/data/useDashboard';
import moment from 'moment';
import { BarChartHorizontalBig, Star } from 'lucide-react';
import TrendBreakdownModal from '@/components/trending-card/TrendBreakdownModal';
import { isEmpty } from 'lodash';
import TrendingLine from './TrendingLine';

export function TrendingCard({
  className, topics, total,
}) {
  const { defaultDate } = useDefaultDateStore();
  const isWeek = moment(defaultDate.end).diff(moment(defaultDate.start), 'months') >= 3 ? 1 : 0;
  const [openTrendBreakdown, setOpenTrendBreakdown] = useState(false);
  const [onlyFavorites, setOnlyFavorites] = useState(false);
  const {
    isLoading, isFetching, data,
  } = useDashboard({
    start: defaultDate.start,
    end: defaultDate.end,
    week: isWeek,
    onlyFavorites,
  });

  const graphTotal = topics.length > 0 ? (
    parseInt(topics[0].negative_count, 10)
    + parseInt(topics[0].positive_count, 10)
    + parseInt(topics[0].neutral_count, 10)
  ) : 0;

  return (
    <>
      <TrendBreakdownModal visible={openTrendBreakdown} setOpenModal={setOpenTrendBreakdown} />
      <Card className={cn('py-[18px] mb-4', className)}>
        <Card.Header>
          <div className="flex items-center divide-y-0 divide-x-2 flex-1 gap-7 divide-solid divide-[#DFE9F1]">
            <Card.Header.Title title="Trending" />
          </div>
          <Card.Header.Actions>
            <Link to="/taxonomy">
              <Button className="ant-btn ant-btn-secondary mt-[-2px]">
                View all
              </Button>
            </Link>
            <Button
              className="!border-none !text-[#44596C] !text-sm !px-2 hover:!text-[#202324] hover:!bg-[#EFF4F8] disabled:!bg-[#F6F9FB] disabled:!text-[#CFDBE4]"
              style={{ backgroundColor: onlyFavorites ? '#EFF4F8' : 'transparent' }}
              onClick={() => setOnlyFavorites(!onlyFavorites)}
            >
              <Star width={20} fill={onlyFavorites ? '#44596C' : 'transparent'} />
            </Button>
            <Button
              className="!border-none !text-[#44596C] !text-sm !px-1 hover:!text-[#202324] hover:!bg-[#EFF4F8] disabled:!bg-[#F6F9FB] disabled:!text-[#CFDBE4]"
              onClick={() => setOpenTrendBreakdown(true)}
            >
              <BarChartHorizontalBig width={20} />
            </Button>
          </Card.Header.Actions>
        </Card.Header>
        <Card.Body>
          {isEmpty(data) && onlyFavorites ? !isLoading && (
            <div className="pt-24 text-center text-sm text-[#75899B] font-[Gordita-Regular]">
              No favorite segments found
            </div>
          ) : (
            <List
              loading={isLoading || isFetching}
              className="[&_.ant-list-header]:p-0 [&_.ant-list-header]:!border-0 [&_.ant-list-items]:space-y-2"
              dataSource={data?.most_discussed_topics}
              renderItem={(item) => (
                <TrendingLine
                  topic={item}
                  total={total}
                  graphTotal={graphTotal}
                  filters={{
                    start: defaultDate.start,
                    end: defaultDate.end,
                  }}
                />
              )}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
}
