import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const useFavoritesStore = create(devtools(persist((set) => ({
  favorites: false,
  setFavorites: () => set((old) => ({ favorites: !old.favorites })),
  reset: () => set({ favorites: false }),
}), {
  name: 'favorites',
})), {
  name: 'useFavoritesStore',
});
