import React from 'react';
import { cn } from '@/helpers/util';
import { Tooltip, Divider } from 'antd';
import { ArrowUpIcon, ArrowDownIcon } from 'lucide-react';

export function Count({ className, children }) {
  return (
    <div className={cn('flex items-center gap-x-0.5', className)}>
      {children}
    </div>
  );
}

/**
 * @param {import('antd/lib/tooltip/index').TooltipProps} props
 * @returns {JSX.Element}
 */
Count.Tooltip = function CountTooltip({
  title, placement = 'bottom', children, ...rest
}) {
  return (
    <Tooltip title={title} placement={placement} {...rest}>
      <span>
        {children}
      </span>
    </Tooltip>
  );
};

Count.Title = function CountText({ className, children }) {
  return (
    <span className={cn('block text-[#202324] font-[Gordita-Medium]', className)}>
      {children}
    </span>
  );
};

/**
 * @param {import('antd/lib/divider/index').DividerProps} props
 * @returns {JSX.Element}
 */
Count.Divider = function CountDivider({ type = 'vertical', className, ...rest }) {
  return (
    <Divider
      type={type}
      className={cn('[&.ant-divider-vertical]:border-[#CEDBE4] [&.ant-divider-vertical]:h-5 [&.ant-divider-vertical]:mx-1.5', className)}
      {...rest}
    />
  );
};

Count.Trend = function CountTrend({ trend }) {
  return trend === 'ascending' ? (
    <ArrowUpIcon size={14} strokeWidth={2.5} color="#F17E99" />
  ) : (
    <ArrowDownIcon size={14} strokeWidth={2.5} color="#A8BCCF" />
  );
};

Count.Procent = function CountProcent({ trend, percent, className }) {
  return (
    <span className={cn('text-[#75899B] text-[12px] leading-6 w-13', className)}>
      {trend === 'ascending' ? '+' : '-'}
      {percent}
      %
    </span>
  );
};

Count.TrendAndProcent = function CountTrendAndProcent({ trend, percent, className }) {
  return (
    <div className={cn('flex items-center gap-x-0.5', className)}>
      <Count.Trend trend={trend} />
      <Count.Procent trend={trend} percent={percent} />
    </div>
  );
};
