import React from 'react';
import Card from '@/components/card';
import { cn } from '@/helpers/util';
import SentimentGraph from '@/components/bucket/SentimentGraph';
import { getSentimentsAvg } from '@/components/bucket/lib/utils';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { calculateDifference } from '@/components/taxonomy/utils';

export function SentimentOverviewCard({ overview, className }) {
  const avgList = getSentimentsAvg(overview);
  const evolution = ((overview?.positive - overview?.negative) - (overview?.previous_positive - overview.previous_negative)) * 50;
  const score = (overview?.positive - overview?.negative) * 100;

  return (
    <Card className={cn('pt-4 pb-8 mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title="Global sentiment score" />
        </div>
      </Card.Header>
      <Card.Body className="mt-6 flex justify-center">
        <div className="flex gap-8">
          <SentimentGraph
            data={avgList}
            style={{ marginTop: 20 }}
            ratio={overview}
            score={score}
            showTrend={false}
            type={(
              <p className="mt-2 flex items-center justify-center">
                <span style={{ color: evolution > 0 ? '#5FC59A' : '#F17E99', top: '4px' }}>
                  {evolution > 0 ? <ArrowUp size={16} strokeWidth={2.5} />
                    : <ArrowDown size={16} strokeWidth={2.5} />}
                </span>
                <span>{`${evolution > 0 ? '+' : ''}${evolution.toFixed(0)}%`}</span>
              </p>
            )}
          />
          <div className="flex flex-col justify-center gap-8 mt-3">
            <div className="table border-spacing-2">
              {
                  avgList.map((entry) => (
                    <div className="table-row">
                      <div className="table-cell align-middle">
                        <div style={{ backgroundColor: entry.graphColor }} className={cn('w-2 h-2 rounded-full')} />
                      </div>
                      <div className="table-cell">
                        <Link
                          to={`/discovery?sentiment=${entry.name.toLowerCase()}`}
                          className="redirect-link underline !text-[#44596C] font-[Gordita-Regular] sentiment-link-redirect"
                        >
                          <span>{entry.name}</span>
                        </Link>
                      </div>
                      <div className="table-cell">
                        <div className="text-[#202324] font-[Gordita-Medium]">
                          {entry.value}
                          %
                        </div>
                      </div>
                      <Tooltip title="Change in feedback compared to the previous period">
                        <div className="flex items-center gap-x-0.5">
                          {overview[`${entry.name.toLowerCase()}_trend`] === 'ascending' ? (
                            <ArrowUp className="ml-[-2px]" size={16} strokeWidth={2.5} color="#F17E99" />
                          ) : (
                            <ArrowDown className="ml-[-2px]" size={16} strokeWidth={2.5} color="#A8BCCF" />
                          )}
                          <span className="text-[#75899B] text-xs leading-6 w-11">
                            {calculateDifference(overview[`${entry.name.toLowerCase()}`], overview[`previous_${entry.name.toLowerCase()}`]) !== 0
                                && (overview[`${entry.name.toLowerCase()}_trend`] === 'ascending' ? '+' : '-')}
                            {calculateDifference(overview[`${entry.name.toLowerCase()}`], overview[`previous_${entry.name.toLowerCase()}`])}
                            %
                          </span>
                        </div>
                      </Tooltip>
                    </div>
                  ))
               }
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
