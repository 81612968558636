import {
  Dropdown,
  Radio, Tooltip, Menu,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import useURLState from '@/hooks/useUrlState';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useTemplateStore } from '@/components/filter/store/useTemplateStore';
import { ChevronDown } from 'lucide-react';
import { defaultDates, quarterlyDatesGeneration } from './lib/utils';
import SentimentRangePicker from './SentimentRangePicker';

const formatDate = (date) => moment(date).format('MMM D, YYYY');
const getTooltipText = (option) => `${formatDate(option.start)} - ${formatDate(option.end)}`;

export function SentimentDateFilter() {
  const { defaultDate, setDefaultDate } = useDefaultDateStore();
  const { setTemplate } = useTemplateStore();
  const { urlQueryState, setUrlQueryParams, deleteUrlQueryParams } = useURLState();
  const quarterlyDates = useMemo(() => quarterlyDatesGeneration().reverse(), []);

  const [showPicker, setShowPicker] = useState(defaultDate.name === 'CUSTOM');
  const [selectedQuarterly, setSelectedQuarterly] = useState('QX');

  const handleChange = (e) => {
    const { value: name } = e.target;

    setTemplate([]);
    if (name === 'CUSTOM' || name === 'QX') return;
    setSelectedQuarterly('QX');
    setShowPicker(false);
    setUrlQueryParams({ name });
    deleteUrlQueryParams(['start', 'end']);
    setDefaultDate(defaultDates.find((item) => item.name === name));
  };

  const handleChangeQuarterly = (name) => {
    setTemplate([]);

    setSelectedQuarterly(name);
    setUrlQueryParams({ name });
    deleteUrlQueryParams(['start', 'end']);
    const quarterlyOption = quarterlyDates.find((item) => item.name === name);
    setDefaultDate({ name: 'QX', start: quarterlyOption.start, end: quarterlyOption.end });
  };

  const handleChangePicker = (values) => {
    let [start, end] = values;
    start = start.format('YYYY-MM-DD');
    end = end.format('YYYY-MM-DD');

    setSelectedQuarterly('QX');
    setDefaultDate({ name: 'CUSTOM', start, end });
    setUrlQueryParams({ name: 'CUSTOM', start, end });
  };

  useEffect(() => {
    if (defaultDate.name === urlQueryState?.name) return;

    if (defaultDate?.name === 'QX' || urlQueryState?.name === 'QX') {
      const quarterlyOption = quarterlyDates.find((item) => item.end === defaultDate.end && item.start === defaultDate.start);
      if (!quarterlyOption) return;
      setDefaultDate({ name: 'QX', start: quarterlyOption.start, end: quarterlyOption.end });
      setSelectedQuarterly(quarterlyOption.name);
    } else {
      const option = defaultDates.find((item) => item.name === urlQueryState?.name);
      if (!option) return;
      setDefaultDate(option);
    }
  }, [urlQueryState?.name]);

  return (
    <Radio.Group className="cz-default-date" value={defaultDate.name} onChange={handleChange}>
      {defaultDates.map((item) => (
        <Radio.Button key={item.name} value={item.name}>
          <Tooltip
            placement="bottom"
            overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
            title={getTooltipText(item)}
          >
            {item.name}
          </Tooltip>
        </Radio.Button>
      ))}
      <Dropdown
        className="quarterly-select-filter"
        overlay={(
          <Menu>
            {quarterlyDates.map((item) => (
              <Menu.Item
                key={item.name}
                onClick={() => handleChangeQuarterly(item.name)}
                className={selectedQuarterly === item.name ? 'ant-dropdown-menu-item-selected' : ''}
              >
                <span className="block w-full text-[#75899B] text-sm">
                  {moment().isBetween(item.start, item.end) ? (
                    <div className="flex justify-between items-center">
                      <span>{item.name}</span>
                      <span className="text-xs text-right">Ongoing</span>
                    </div>
                  ) : item.name}
                </span>
              </Menu.Item>
            ))}
          </Menu>
          )}
        overlayClassName="!min-w-[192px]"
        placement="bottomRight"
        align={{ offset: [20, 10] }}
        trigger={['click']}
      >
        <Radio.Button key="QX" value="QX">
          <Tooltip
            placement="bottom"
            overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
            title="QX"
            className="flex items-center"
          >
            {selectedQuarterly}
            <ChevronDown size={12} strokeWidth={3} className="ml-1.5" />
          </Tooltip>
        </Radio.Button>
      </Dropdown>
      <Radio.Button value="CUSTOM" onClick={() => setShowPicker(true)}>
        {showPicker ? (
          <SentimentRangePicker
            autoFocus={defaultDate.name !== 'CUSTOM'}
            size="small"
            bordered={false}
            onChange={handleChangePicker}
            style={{ width: 250 }}
            showOnMount={defaultDate.name !== 'CUSTOM'}
            defaultValue={
              defaultDate.name === 'CUSTOM'
                ? [
                  moment(defaultDate.start, 'YYYY-MM-DD').startOf('day'),
                  moment(defaultDate.end, 'YYYY-MM-DD').startOf('day'),
                ]
                : undefined
            }
          />
        ) : (
          <Tooltip
            placement="bottom"
            overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
            title="Custom"
          >
            Custom
          </Tooltip>
        )}
      </Radio.Button>
    </Radio.Group>
  );
}
