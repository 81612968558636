/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import {
  Button, DatePicker, Dropdown, Empty, Input, Menu, Select, Spin, Tag, Tooltip, message,
} from 'antd';
import {
  ArrowLeftIcon,
  PlusIcon, SearchIcon, View, X, XIcon,
} from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@/helpers/util';
import debounce from 'lodash/debounce';
import moment from 'moment';
import useCustomFields from '../../discovery/hooks/useCustomFields';
import { useCustomFieldsValues } from '../../discovery/hooks/useCustomFieldsValues';
import { useFilters, useFiltersDispatch } from '../context';

export const improveLabel = (value) => {
  switch (value) {
    case 'CLZ$$all$$': return 'All';
    default: return value;
  }
};

export const showLabel = (v) => {
  const value = JSON.parse(v);

  if (Array.isArray(value)) {
    return value.join(', ');
  }

  if (typeof value === 'object' && value !== null) {
    if (value.start && value.end) {
      return `${moment(value.start).format('D MMM YYYY')} - ${moment(value.end).format('D MMM YYYY')}`;
    }

    return v;
  }

  switch (value) {
    case true: return 'true';
    case false: return 'false';
    case '': return '""';
    case null: return 'null';
    default: return value;
  }
};

function CustomFieldTag(props) {
  const {
    label, value, closable, onClose,
  } = props;

  return (
    <Tag
      color={value}
      closeIcon={<XIcon size={12} color="#CEDBE4" />}
      closable={closable}
      onClose={(e) => onClose(e, label)}
      className="!text-[#202324] !py-1 !bg-[#F6F9FB] !rounded flex items-center gap-1 [&>.ant-tag-close-icon]:flex"
    >
      {label.length > 16 ? (
        <Tooltip title={label}>
          <span>
            {label.slice(0, 18)}
            ...
          </span>
        </Tooltip>
      ) : (
        <span>
          {improveLabel(label)}
        </span>
      )}
    </Tag>
  );
}

function CustomFieldRow({
  field, handleDeselect, handleMenuClick,
}) {
  const { data, isLoading } = useCustomFields({ added: true });
  const selectedField = !isLoading && data.find((item) => item.key === field.id);

  return selectedField ? (
    <div className="px-4 mt-1.5">
      <span className="block text-[#75899B] font-[Gordita-Regular] text-xs">{field.name}</span>
      <ul className="mt-2 flex gap-2 p-0 list-none flex-wrap">
        {field.values.map((value) => (
          <li key={value}>
            <CustomFieldTag
              label={value}
              value={value}
              closable
              onClose={() => handleDeselect(value)}
            />
          </li>
        ))}
        <Dropdown
          trigger={['click']}
          onClick={(e) => e.stopPropagation()}
          align={{ offset: [0, -10] }}
          overlay={(
            <Menu onClick={(e) => e.domEvent.stopPropagation()} className="!rounded-md w-64 !min-w-max shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1] h-64 overflow-y-scroll no-scrollbar">
              <CustomFieldItem key={selectedField.key} visible item={selectedField} handleMenuClick={handleMenuClick} />
            </Menu>
        )}
        >
          <Tag
            className="group/plus !min-h-[32px] !border-none !text-[#202324] !bg-[#F6F9FB] !rounded flex items-center gap-1 [&>.ant-tag-close-icon]:flex"
          >
            <PlusIcon size={14} strokeWidth={2} className="h-fit text-[#CEDBE4] group-hover/plus:text-[#A8BCCF]" />
          </Tag>
        </Dropdown>

      </ul>
    </div>
  ) : null;
}

export function CustomFieldItem({
  item, visible = false, handleMenuClick, ...props
}) {
  const {
    key, name, data_type, example, platform_type, type,
  } = item;

  const ref = useRef(null);
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(visible);
  const [currentHeight, setCurrentHeight] = useState(undefined);

  const { data, isLoading } = useCustomFieldsValues(active, key, search);

  const handleSearch = debounce((e) => {
    setSearch(e.target.value);
  }, 500);

  const onItemClick = () => {
    if (!active) {
      setActive(true);
      try {
        const scrollNode = ref.current.parentNode.parentNode;
        setCurrentHeight(scrollNode.scrollHeight);
        scrollNode.scrollTo(0, 0);
      } catch {
        // ignore
      }
    }
  };

  const goBack = (e) => {
    e.stopPropagation();
    setActive(false);
    setCurrentHeight(undefined);
  };

  const handleSelect = (e, value) => {
    handleMenuClick(item, value);
    if (!visible) setActive(false);
    setCurrentHeight(undefined);
  };

  const CustomFieldDate = () => {
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);

    return (
      <div className="space-y-2 mt-2">
        <div className="space-x-1 w-full flex">
          <DatePicker
            placeholder="Start date"
            onChange={(date) => setStartDate(date.startOf('day'))}
          />
          <DatePicker
            placeholder="End date"
            onChange={(date) => setEndDate(date.startOf('day'))}
          />
        </div>
        <Button
          onClick={(e) => handleSelect(e, JSON.stringify({ start: startDate, end: endDate }))}
          className="w-full !mt-2"
          type="primary"
          size="large"
          disabled={!startDate || !endDate}
        >
          Apply
        </Button>
      </div>
    );
  };
  const CustomFieldString = () => (
    <div className="h-full max-w-[420px] overflow-x-scroll overflow-y-scroll">
      {data.length > 0 ? data.map((datum) => (
        <Menu.Item
          {...props}
          key={datum.value}
          className="!p-1.5 flex !text-sm items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB] text-[#202324]"
          onSelect={(e) => handleSelect(e, datum.value)}
        >
          {improveLabel(showLabel(datum.value))}
        </Menu.Item>
      )) : (
        <Empty description="No values" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );

  const CustomFieldType = () => {
    switch (data_type) {
      case 'date':
        return <CustomFieldDate />;
      case 'string':
        return <CustomFieldString />;
      default:
        return <CustomFieldString />;
    }
  };

  return (
    <Menu.Item
      {...props}
      className="custom-field-item flex items-center gap-2 hover:!bg-[#F6F9FB] !px-3 !rounded-[4px] !mb-0 !mt-0"
      onClick={onItemClick}
    >
      <div ref={ref} className={cn(active && 'rounded-md px-3 py-2 absolute top-0 left-0 w-full h-full bg-white shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]')} style={{ minHeight: currentHeight }}>
        {!active ? (
          <>{name}</>
        ) : (
          <div className="h-full">
            <div>
              <div className="flex items-center gap-2.5" onClick={(e) => goBack(e)}>
                <ArrowLeftIcon size={16} />
                <span className="text-sm text-[#202324] max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">{name}</span>
              </div>
              <Input
                prefix={<SearchIcon size={14} />}
                placeholder="Search field"
                className="mb-2 mt-2.5 !font-[Gordita-Regular]"
                onChange={handleSearch}
              />
            </div>
            <div style={{ height: 'calc(100% - 70px)' }}>
              {isLoading ? (
                <div className="text-center p-6">
                  <Spin />
                </div>
              ) : (
                <CustomFieldType />
              )}
            </div>
          </div>
        )}
      </div>
    </Menu.Item>
  );
}

export default function CustomFieldFilter({ defaultValue = [], name }) {
  const { filters } = useFilters();
  const dispatch = useFiltersDispatch();
  const { data, isLoading } = useCustomFields({ added: true });

  const [list, setList] = useState([]);

  useEffect(() => {
    const initialList = [];
    defaultValue.forEach((item) => {
      // eslint-disable-next-line no-shadow
      const existing = initialList.find((existing) => existing.id === item.platform_field.id);
      if (!existing) {
        initialList.push({
          id: item.platform_field ? item.platform_field.id : item.id,
          name: item.name ?? item.platform_field.name,
          data_type: item.data_type ?? item.platform_field.data_type,
          values: item.values ?? [showLabel(item.value)],
        });
      } else {
        const newValue = showLabel(item.value);
        if (newValue === 'CLZ$$all$$') {
          existing.values = [newValue];
        } else {
          existing.values = existing.values.filter((it) => it !== 'CLZ$$all$$');
          existing.values.push(newValue);
        }
      }
    });

    setList(initialList);
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'custom_fields', value: initialList });
  }, []);

  const handleMenuClick = (field, value) => {
    const newField = {
      id: field.key,
      name: field.name,
      data_type: field.data_type,
      values: [showLabel(value)],
    };

    if (list.find((item) => item.id === field.key)) {
      setList((prevState) => prevState.map((item) => {
        if (item.id !== field.key) return item;
        if (item.values.includes(showLabel(value))) {
          message.error('Value already selected');
          return item;
        }

        const newValue = showLabel(value);
        const newItem = {
          ...item,
        };

        if (newValue === 'CLZ$$all$$') {
          newItem.values = [newValue];
        } else {
          newItem.values = newItem.values.filter((it) => it !== 'CLZ$$all$$');
          newItem.values.push(newValue);
        }

        return newItem;
      }));

      dispatch({
        type: 'SET_FILTERS_FIELD',
        field: 'custom_fields',
        value: filters.custom_fields.map((item) => {
          if (item.id !== field.key) return item;
          if (item.values.includes(showLabel(value))) {
            return item;
          }
          return {
            ...item,
            values: [...item.values, showLabel(value)],
          };
        }),
      });

      return;
    }

    // doesn't exist in list
    setList((prevState) => [...prevState, newField]);
    dispatch({ type: 'ADD_FILTERS_FIELD', field: 'custom_fields', value: newField });
  };

  const handleFieldDeselect = async (value) => {
    const field = list.map((item) => {
      if (item.values.includes(value)) {
        return {
          ...item,
          values: item.values.filter((val) => val !== value),
        };
      }
      return item;
    });
    const newField = field.filter((item) => item.values.length > 0);
    setList(newField ?? []);

    const newFields = filters.custom_fields.map((item) => {
      if (item.values.includes(value)) {
        return {
          ...item,
          values: item.values.filter((val) => val !== value),
        };
      }

      return item;
    });

    const newFiltersByField = newFields.filter((item) => item.values.length > 0);
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'custom_fields', value: newFiltersByField ?? [] });
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setList([]);
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'custom_fields', value: [] });
  };

  const handleFieldClear = (id) => {
    setList((prevState) => prevState.filter((item) => item.id !== id));
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'custom_fields', value: filters.custom_fields.filter((item) => item.id !== id) });
  };

  const content = data?.length > 0 ? data.map((item) => (
    <CustomFieldItem key={item.key} item={item} handleMenuClick={handleMenuClick} />
  )) : (
    <div className="pt-5 mx-auto">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No custom fields added to Clientzen" />
    </div>
  );

  return (
    <div className="space-y-0.5">
      <Dropdown
        trigger={['click']}
        overlayClassName="!px-3"
        align={{ offset: [0, -10] }}
        overlay={(
          <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1] h-[320px] max-w-[480px] overflow-y-scroll overflow-x-scroll">
            {isLoading ? (
              <div className="p-5 flex items-center justify-center">
                <Spin />
              </div>
            ) : content}
          </Menu>
        )}
      >
        <div className="group flex flex-col border-0 border-b border-solid border-[#EFF4F8] hover:border-[#DFE9F1] pb-3 cursor-pointer">
          <div className="group/button px-4 hover:border-[#DFE9F1] flex items-center justify-between text-[#75899B] text-sm">
            <span className="group-hover:text-[#44596C]">{name}</span>
            <div className="flex gap-1">
              <Button
                type="text"
                onClick={handleClear}
                className={cn(
                  'opacity-0 group-hover:opacity-100 flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B] hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                  list.length === 0 && 'group-hover:opacity-0',
                )}
              >
                <X size={20} />
              </Button>
              <Button
                type="text"
                className={cn(
                  'flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B]',
                  list.length === 0 ? 'group-hover/button:!bg-[#EFF4F8] group-hover/button:!text-[#44596C]' : 'hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                )}
              >
                <PlusIcon size={20} />
              </Button>
            </div>
          </div>
          <ul className="m-0 p-0 space-y-4">
            {list.map((field) => (
              <CustomFieldRow
                key={field.id}
                field={field}
                handleDeselect={handleFieldDeselect}
                handleClear={() => handleFieldClear(field.id)}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </ul>
        </div>
      </Dropdown>
    </div>
  );
}
