import moment from 'moment';

export const defaultDates = [
  {
    name: '7D',
    start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 7 days',
  },
  {
    name: '30D',
    start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 30 days',
  },
  {
    name: '3M',
    start: moment().subtract(3, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 3 months',
  },
  {
    name: '6M',
    start: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 6 months',
  },
];

export function quarterlyDatesGeneration() {
  let initialYear = 2023;
  const quarterlyMonths = [
    {
      name: 'Q1',
      start_month: 0,
      end_month: 2,
    },
    {
      name: 'Q2',
      start_month: 3,
      end_month: 5,
    },
    {
      name: 'Q3',
      start_month: 6,
      end_month: 8,
    },
    {
      name: 'Q4',
      start_month: 9,
      end_month: 11,
    },
  ];
  const quarterlyList = [];

  while (initialYear <= moment().year()) {
    const currentYear = initialYear;
    quarterlyMonths.forEach((item) => {
      const start = moment().month(item.start_month).year(currentYear).startOf('month');
      if (start.isBefore(moment())) {
        quarterlyList.push({
          name: `${item.name} ${currentYear}`,
          start: start.format('YYYY-MM-DD'),
          end: moment().month(item.end_month).year(currentYear).endOf('month')
            .format('YYYY-MM-DD'),
          label: `${item.name} ${currentYear}`,
        });
      }
    });

    initialYear++;
  }
  return quarterlyList;
}
