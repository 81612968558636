import React from 'react';
import { useStore } from 'zustand';
import { TaxonomyTableStoreContext } from './Provider';

const useTaxonomyTableStore = (selector) => {
  const store = React.useContext(TaxonomyTableStoreContext);

  if (!store) {
    throw new Error('useTaxonomyTableStore must be used within a TaxonomyTableProvider');
  }

  return useStore(store, selector);
};

export { useTaxonomyTableStore };
