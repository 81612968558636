/* eslint-disable no-unused-vars */
import React from 'react';
import { Count } from '../../Count';

export function FeedbackColumn({ value, trend, percent }) {
  return (
    <Count>
      <Count.Tooltip title="The ammount of feedback in the current period">
        <Count.Title>{value}</Count.Title>
      </Count.Tooltip>
      <Count.Divider />
      <Count.Tooltip title="Change in feedback compared to the previous period">
        <Count.TrendAndProcent trend={trend} percent={percent} />
      </Count.Tooltip>
    </Count>
  );
}
