import axios from '@/api/axios';
import auth from '@/api/auth';
import { buildQuery } from '@/helpers/filters';

const create = (values) => axios.post(
  '/api/ai-topics',
  values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const show = (id, filters = {}, params = {}) => axios.get(buildQuery(
  `/api/ai-topics/${id}`, filters, {
    team_id: auth.team().id,
    ...params,
  },
)).then(({ data }) => data)
  .catch((e) => Promise.reject(e));

export default {
  create,
  show,
};
