import React, { useEffect, useMemo } from 'react';
import { Table } from 'antd';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { cn } from '@/helpers/util';
import { ChevronUpIcon, ChevronDownIcon } from 'lucide-react';

import useTopics from '@/components/discovery/hooks/useTopics';
import useTaxonomy from '@/components/taxonomy/hooks/useTaxonomy';
import useSubtopics from '@/components/bucket/hooks/useSubtopics';
import { useHistory } from 'react-router-dom';
import { useSearchStore } from '@/store/zustand/useSearchStore';
import { useFavoritesStore } from '@/store/zustand/useFavoritesStore';
import { getColumns } from './Table.columns';
import { useTaxonomyTableStore } from '../../store/useStore';

function TaxonomyExpand({
  expandable, expanded, onExpand, record,
}) {
  const props = {
    className: 'expand-icon',
    color: '#44596C',
    width: 20,
    height: 16,
    strokeWidth: 3,
    'aria-label': expanded ? 'Expand' : 'Collapse',
    onClick: (e) => { onExpand(record, e); e.stopPropagation(); },
  };

  if (!expandable) return null;

  return expanded ? <ChevronUpIcon {...props} /> : <ChevronDownIcon {...props} />;
}

const SubtopicRow = ({
  filters, columns, record, ...props
}) => {
  const { setSubtopics, data: tableData } = useTaxonomyTableStore();
  const history = useHistory();
  const subtopics = useSubtopics(record.id, {
    start: filters.start,
    end: filters.end,
  });

  const isMRR = useMemo(() => tableData.some((rec) => rec.mrr?.value > 0), [tableData]);
  const defaultColumns = useMemo(() => {
    const locCols = getColumns(history).slice(0, 1);
    locCols[0].width = '100%';
    return locCols;
  }, [columns]);

  const localColumns = useMemo(
    () => (
      columns.length < 2
        ? defaultColumns
        : getColumns(history, record).filter((col) => col.dataIndex !== 'mrr' || isMRR)
    ),
    [columns],
  );

  useEffect(() => {
    if (!subtopics.data) return;
    setSubtopics(record.groups[0].id, record.id, subtopics.data);
  }, [subtopics.data]);

  return (
    <Table
      className="taxonomy-subtopics-table"
      rowClassName="subtopic-row"
      showHeader={false}
      columns={localColumns}
      rowKey={(rec) => rec.id}
      loading={subtopics.isLoading}
      dataSource={subtopics.data}
      pagination={false}
      {...props}
    />
  );
};

const TopicRow = ({
  filters, columns, record, ...props
}) => {
  const { setTopics } = useTaxonomyTableStore();
  const { defaultDate } = useDefaultDateStore();
  // const { search } = useSearchStore();
  const topic = useTopics(record.id, {
    start: filters.start,
    end: filters.end,
  }, { highest_feedback: 1 });

  const { favorites } = useFavoritesStore();
  const favoritesTopics = useMemo(() => topic.data?.filter((rec) => rec.is_favorite), [topic.data]);

  useEffect(() => {
    if (!topic.data) return;
    setTopics(record.id, topic.data);
  }, [topic.data]);

  return (
    <Table
      className="taxonomy-topics-table"
      rowClassName="topic-row"
      showHeader={false}
      expandable={{
        expandRowByClick: true,
        // expandedRowKeys: search && [topic.data.filter((rec) => rec.name.toLowerCase().includes(search.toLowerCase()))[0].id],
        rowExpandable: (rec) => rec.has_subtopics,
        expandedRowRender: (rec) => <SubtopicRow filters={defaultDate} columns={columns} record={rec} {...props} />,
        expandIcon: TaxonomyExpand,
      }}
      columns={columns}
      loading={topic.isLoading}
      rowKey={(rec) => rec.id}
      dataSource={favorites ? favoritesTopics : topic.data}
      pagination={false}
      {...props}
    />
  );
};

export default function TaxonomyTable({
  className, columns, dataSource, loading, ...props
}) {
  const { defaultDate } = useDefaultDateStore();
  const { search } = useSearchStore();
  const { setData } = useTaxonomyTableStore();
  const taxonomy = useTaxonomy({ start: defaultDate.start, end: defaultDate.end, search_value: search });
  const history = useHistory();

  const isMRR = useMemo(() => taxonomy.data?.some((rec) => rec.mrr?.value > 0), [taxonomy.data]);
  const localColumns = useMemo(
    () => getColumns(history).filter((col) => col.dataIndex !== 'mrr' || isMRR), [],
  );

  const { favorites } = useFavoritesStore();
  const favoritesCategories = useMemo(() => taxonomy.data?.filter((rec) => rec.has_favorites_children), [taxonomy.data]);

  const expandedRowKeys = useMemo(() => {
    if (favorites) return favoritesCategories.map((rec) => rec.id);
    return null;
  }, [favoritesCategories, favorites]);

  useEffect(() => {
    if (!taxonomy.data) return;
    setData(taxonomy.data);
  }, [taxonomy.data]);

  return (
    <Table
      className={cn('taxonomy-table', className)}
      rowClassName="category-row"
      columns={columns || localColumns}
      loading={loading || taxonomy.isLoading}
      expandable={{
        expandRowByClick: true,
        expandedRowKeys,
        rowExpandable: (rec) => rec.has_children,
        expandedRowRender: (record) => <TopicRow filters={defaultDate} columns={columns || localColumns} record={record} {...props} />,
        expandIcon: TaxonomyExpand,
      }}
      rowKey={(rec) => rec.id}
      dataSource={dataSource || (favorites ? favoritesCategories : taxonomy.data)}
      pagination={false}
      {...props}
    />
  );
}
