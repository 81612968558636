import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';

const TaxonomyDeleteCategoryModal = ({
  setVisible,
  visible,
  ...props
}) => {
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Modal
      title="Can't delete category"
      onOk={onCancel}
      onCancel={onCancel}
      footer={[
        <Button className="float-left" key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onCancel}>
          Ok
        </Button>,
      ]}
      visible={visible}
      className="taxonomy-modal"
      {...props}
    >
      <div className="category-modal-body">
        <p className="mb-0">
          To delete the category you have to delete all the topics within that category.
        </p>
      </div>
    </Modal>
  );
};

export default TaxonomyDeleteCategoryModal;
