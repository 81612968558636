import React, { useEffect, useMemo } from 'react';
import {
  Empty, List, message, Button,
} from 'antd';
import useSubtopics from '@/components/bucket/hooks/useSubtopics';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useHistory, useParams } from 'react-router-dom';
import SegmentLine from '@/components/ui/segment/SegmentLine';

function FilterItem({ item, total }) {
  const history = useHistory();
  const { topicId, groupId, subtopicId } = useParams();
  const barPercent = useMemo(() => {
    if (!item) return 0;

    const count = Math.abs(item.count.previous_value - item.count.value);
    const procent = (count / item.count.previous_value) * 100;
    if (procent > 100) return 100;
    if (Number.isNaN(procent)) return 0;
    return procent.toFixed(0);
  }, [item]);
  const width = Math.ceil(total > 0 ? (item.count.value / total) * 100 : 0);

  const value = useMemo(() => {
    const count = Math.abs(item.count.value);
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count;
  });

  const handleClick = () => {
    if (parseInt(subtopicId, 10) !== item.id) history.push(`/topic/overview/${topicId}/${groupId}/${item.id}`);
    else history.push(`/topic/overview/${topicId}/${groupId}`);
  };

  return (
    <SegmentLine
      selected={item.id === parseInt(subtopicId, 10)}
      name={item.name}
      width={width}
      percent={barPercent}
      trend={item.count.trend}
      value={value}
      onClick={handleClick}
    />
  );
}

export default function SegmentVerticalList({
  limit,
  handleSeeMore,
  seeMoreText,
  setHasSubtopics,
}) {
  const { topicId } = useParams();

  const { defaultDate } = useDefaultDateStore();
  const {
    data: subtopics,
    isLoading,
    isError,
    error,
  } = useSubtopics(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
  });
  const visibleSubtopics = useMemo(() => {
    if (subtopics && limit) {
      return subtopics.slice(0, limit);
    }

    return subtopics;
  }, [subtopics, limit]);

  const total = subtopics && subtopics.length > 0
    ? parseInt(subtopics[0].count.value, 10)
    : 0;

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  useEffect(() => {
    setHasSubtopics(visibleSubtopics && visibleSubtopics.length);
  }, [visibleSubtopics]);

  if (!visibleSubtopics || !visibleSubtopics.length) {
    return null;
  }

  return (
    <div className="w-1/2 px-5 py-4 border-0 border-r border-solid border-[#E5ECF3]">
      <span className="text-sm">Subsegments</span>
      <div>
        <List
          className="[&_.ant-list-header]:p-0 [&_.ant-list-header]:!border-0 [&_.ant-list-items]:space-y-2"
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No trending subsegments found"
              />
            ),
          }}
          loading={isLoading}
          dataSource={visibleSubtopics}
          renderItem={(item) => <FilterItem item={item} total={total} />}
        />
        {visibleSubtopics
        && subtopics
        && visibleSubtopics.length < subtopics.length ? (
          <div className="flex m-2 justify-center">
            <Button onClick={handleSeeMore} size="small" type="secondary">
              {seeMoreText}
            </Button>
          </div>
          ) : null}
      </div>
    </div>
  );
}
