import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  List, Modal, Spin,
} from 'antd';
import './TrendBreakdownModal.less';
import { useTrendBreakdown } from '@/components/trending-card/lib/useTrendBreakdown';
import TrendingLine from '@/components/trending-card/TrendingLine';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { quarterlyDatesGeneration } from '@/components/ui/sentiment-date-filter/lib/utils';
import { Star } from 'lucide-react';
import { isEmpty } from 'lodash';
import { trendPeriods } from './lib/utils';

const TrendBreakdownModal = ({
  visible,
  setOpenModal,
}) => {
  const { defaultDate } = useDefaultDateStore();
  const [graphTotal, setGraphTotal] = useState(100);
  const [topicNames, setTopicNames] = useState([]);
  const [topics, setTopics] = useState([]);
  const [quarterlySelectedIndex, setQuarterlySelectedIndex] = useState(0);
  const [onlyFavorites, setOnlyFavorites] = useState(false);
  const quarterlyDates = useMemo(() => quarterlyDatesGeneration().reverse(), []);
  const quarterlySelected = useMemo(() => {
    const foundQuarterly = quarterlyDates.find((item) => item.start === defaultDate.start && item.end === defaultDate.end);
    if (foundQuarterly) {
      return foundQuarterly.name;
    }
    return null;
  }, [quarterlyDates, defaultDate]);

  const trendPeriod = useMemo(() => {
    if (quarterlySelected) {
      setQuarterlySelectedIndex(quarterlyDates.findIndex((item) => item.name === quarterlySelected));
      const periods = quarterlyDates.slice(quarterlySelectedIndex, quarterlySelectedIndex + 3);

      return {
        period1: periods[0]?.name,
        period2: periods[1]?.name,
        period3: periods[2]?.name,
      };
    }
    const foundPeriod = trendPeriods.find((item) => item.name === defaultDate?.name);
    if (foundPeriod) {
      const { name, ...rest } = foundPeriod;
      return rest;
    }
    return {};
  }, [defaultDate, trendPeriods, quarterlySelected, quarterlyDates, setQuarterlySelectedIndex, quarterlySelectedIndex]);

  const {
    data = {}, isLoading,
  } = useTrendBreakdown({
    start: defaultDate.start,
    end: defaultDate.end,
    onlyFavorites,
  });

  const handleCancel = () => {
    setOpenModal(false);
  };

  function getColorMap(index) {
    const colors = {
      0: 'bg-[#5898EC] group-hover:bg-[#5898EC] h-4',
      1: 'bg-[#AF7CE8] group-hover:bg-[#AF7CE8] h-4',
      2: 'bg-[#6CD0A3] group-hover:bg-[#6CD0A3] h-4',
    };

    return colors[index];
  }

  useEffect(() => {
    if (data.result && data.total && Object.keys(data.result).length > 0) {
      setTopicNames(Object.keys(data.result));
      setTopics(data.result);
      setGraphTotal(data.total);
    }
    if (isEmpty(data?.result)) {
      setTopicNames([]);
      setTopics([]);
    }
  }, [data]);

  return (
    <Modal
      title="Trend breakdown"
      visible={visible}
      className="trend-breakdown-modal"
      width={774}
      onCancel={handleCancel}
      closeIcon={false}
      destroyOnClose
      centered
    >
      <div className="trend-breakdown-header-periods">
        <ul>
          {trendPeriod?.period1 && <li className="period1">{trendPeriod.period1}</li>}
          {trendPeriod?.period2 && <li className="period2">{trendPeriod.period2}</li>}
          {trendPeriod?.period3 && <li className="period3">{trendPeriod.period3}</li>}
        </ul>
        <Button
          className="!border-none !text-[#44596C] !text-sm !px-2 hover:!text-[#202324] hover:!bg-[#EFF4F8] disabled:!bg-[#F6F9FB] disabled:!text-[#CFDBE4] float-right"
          style={{ backgroundColor: onlyFavorites ? '#EFF4F8' : 'transparent', bottom: '5px' }}
          onClick={() => setOnlyFavorites(!onlyFavorites)}
        >
          <Star width={20} fill={onlyFavorites ? '#44596C' : 'transparent'} />
        </Button>
      </div>
      <div className="trend-breakdown-graph">
        {(isLoading || !defaultDate)
         && (
         <div className="mx-auto max-w-[1200px]">
           <div className="pt-24 text-center">
             <Spin size="large" />
           </div>
         </div>
         )}

        {isEmpty(topicNames) && onlyFavorites && !isLoading && (
        <div className="pt-24 text-center text-sm text-[#75899B] font-[Gordita-Regular]">
          No favorite segments found
        </div>
        )}

        {topicNames.map((key) => (
          <div key={key}>
            <p className="trend-breakdown-title-topic">{key}</p>
            <List
              loading={isLoading}
              className="[&_.ant-list-header]:p-0 [&_.ant-list-header]:!border-0 [&_.ant-list-items]:space-y-2"
              dataSource={topics[key].slice(0, quarterlyDates.length - quarterlySelectedIndex)}
              renderItem={(item, index) => {
                const colorLineClassName = getColorMap(index);

                return (
                  <TrendingLine
                    topic={item}
                    total={graphTotal}
                    graphTotal={graphTotal}
                    filters={{
                      start: defaultDate.start,
                      end: defaultDate.end,
                    }}
                    showName={false}
                    colorLineClassName={colorLineClassName}
                  />
                );
              }}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default TrendBreakdownModal;
