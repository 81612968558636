/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  useParams, Link, useLocation, useHistory,
} from 'react-router-dom';
import {
  ArrowUp, ArrowDown, LayoutGrid, SearchCode,
} from 'lucide-react';
import { cn } from '@/helpers/util';
import { Button, Divider, Spin } from 'antd';
import { useModalStore } from '@/store/zustand/useModalStore';
import './BucketContainer.styles.less';

import OverallScore from '@/components/bucket/OverallScore';
import AddFilterButton from '@/components/bucket/AddFilterButton';
import SegmentHorizontalList from '@/components/bucket/SegmentHorizontalList';
import SegmentVerticalList from '@/components/bucket/SegmentVerticalList';
import AskMantraButton from '@/components/bucket/AskMantraButton';
import FiltersHorizontalList from '@/components/bucket/FiltersHorizontalList';
import useTopic from './hooks/useTopic';
import { FeedbackContainer } from '../discovery/DiscoveryContainer';
import { mrrFormatter } from './lib/utils';
import BucketGraph from './BucketGraph';
import ReleaseCreateModal from './ReleaseCreateModal';
import { FiltersProvider } from '../filter/context';
import SummaryContainer from './SummaryContainer';

export function StatsCard({
  title = 'All feedback', value = 0, trend = 'ascending', trendValue = 0, children, className, link = null,
}) {
  const isMRR = title === 'MRR';
  const isRatio = title === 'Ratio';
  return (
    <div className={cn('flex flex-col gap-0.5', className)}>
      <span className="text-sm text-[#75899B]">{title}</span>
      {children || (
        <div className="flex items-center gap-0.5 mt-0.5">
          {link ? (
            <Link to={link}>
              <span className="text-[#202324] font-[Gordita-Medium] text-xl mr-1 hover:underline">
                {isMRR && `${mrrFormatter(value)}`}
                {isRatio && `${Math.ceil(value * 100)}%`}
                {!isMRR && !isRatio && value.toFixed(0)}
              </span>
            </Link>
          ) : (
            <span className="text-[#202324] text-xl">
              {isMRR && `${mrrFormatter(value)}`}
              {isRatio && `${Math.ceil(value * 100)}%`}
              {!isMRR && !isRatio && value.toFixed(0)}
            </span>
          )}
          {
            trend === 'ascending'
              ? <ArrowUp size={16} strokeWidth={2.5} color={!isMRR ? '#EDA2B4' : '#A9BCCF'} />
              : <ArrowDown size={16} strokeWidth={2.5} color={!isMRR ? '#A9BCCF' : '#EDA2B4'} />
          }
          {!isRatio ? (
            <span className="text-sm text-[#75899B]">
              {trend === 'ascending' ? '+' : '-'}
              {isMRR && `${mrrFormatter(trendValue)}`}
              {!isMRR && !isRatio && trendValue.toFixed(0)}
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
}

export function BucketContainer() {
  const { type, payload } = useModalStore();
  const {
    topicId, subtopicId, currentTab, groupId,
  } = useParams();
  const [hasSubtopics, setHasSubtopics] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const headerRef = useRef();
  const parentHeaderRef = useRef();
  const { data: topic } = useTopic(topicId);
  const setCurrentTab = useCallback((tab) => {
    let url = location.pathname.replaceAll(`/${currentTab}/`, `/${tab}/`);

    if (tab === 'overview') {
      url = url.replace(`/${subtopicId}`, '');
    }

    history.push(url);
  }, [location.pathname, history]);
  const [headerPadding, setHeaderPadding] = useState(0);
  const [headerWidth, setHeaderWidth] = useState();
  const [headerHeight, setHeaderHeight] = useState();
  const handleSeeMore = useCallback(() => {
    if (subtopicId) history.push(`/topic/research/${topicId}/${groupId}/${subtopicId}`);
    else history.push(`/topic/research/${topicId}/${groupId}`);
  }, [topicId, groupId, subtopicId]);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderPadding(headerRef.current.offsetHeight);
      const resizeObserver = new ResizeObserver((entries) => setHeaderPadding(entries[0].target.offsetHeight));
      resizeObserver.observe(headerRef.current);
    }
    if (parentHeaderRef && parentHeaderRef.current) {
      setHeaderWidth(parentHeaderRef.current.offsetWidth);
      setHeaderHeight(parentHeaderRef.current.offsetHeight);
      const resizeObserver = new ResizeObserver((entries) => {
        setHeaderWidth(entries[0].target.offsetWidth);
        setHeaderHeight(entries[0].target.offsetHeight);
      });
      resizeObserver.observe(parentHeaderRef.current);
    }
  }, []);

  return (
    <FiltersProvider>
      <main className="flex gap-1.5">
        <div ref={parentHeaderRef} className="flex flex-col w-full">
          <div ref={headerRef} className="fixed bg-[#F6F9FB] z-10" style={{ width: headerWidth }}>
            <div className="flex bucket-top-row">
              <AddFilterButton />
              <Divider type="vertical" />
              <Button
                onClick={() => setCurrentTab('overview')}
                className={cn('bucket-btn', {
                  selected: currentTab === 'overview',
                })}
                type="secondary"
              >
                <span className="anticon">
                  <LayoutGrid size={16} />
                </span>
                Overview
              </Button>
              <Button
                onClick={() => setCurrentTab('research')}
                className={cn('bucket-btn', {
                  selected: currentTab === 'research',
                })}
                type="secondary"
              >
                <span className="anticon">
                  <SearchCode size={16} />
                </span>
                Research
              </Button>
              {
                topic ? (
                  <AskMantraButton topic={topic.name} />
                ) : null
              }
            </div>
            <FiltersHorizontalList />
            {
              currentTab === 'research' ? (
                <SegmentHorizontalList />
              ) : null
            }
          </div>
          <div style={{ paddingTop: headerPadding }}>
            {
              currentTab === 'overview' ? (
                <div className="flex flex-col bg-white">
                  <div className="flex">
                    <OverallScore className="w-[410px]" />
                    <BucketGraph topic={topic} />
                  </div>
                  <div className="flex border-0 border-t border-solid border-[#E5ECF3] font-[Gordita-Regular] text-[#44596C]">
                    <SegmentVerticalList limit={10} handleSeeMore={handleSeeMore} seeMoreText="View all" setHasSubtopics={setHasSubtopics} />
                    <div className={cn('px-5 py-4', {
                      'w-1/2': hasSubtopics,
                      'w-full': !hasSubtopics,
                    })}
                    >
                      <SummaryContainer
                        handleSeeMore={handleSeeMore}
                        seeMoreText="View all"
                        disableClick
                        headerText="Top Insights"
                        headerClassName="text-sm"
                      />
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              currentTab === 'research' ? (
                <div className="flex rounded-lg font-[Gordita-Regular] text-[#44596C]">
                  <div className="flex flex-col w-full">
                    <div className="flex bg-white">
                      <div
                        style={{
                          height: headerHeight ? `calc(100vh - ${headerHeight + 76}px)` : undefined,
                        }}
                        className="fixed flex flex-col h-screen w-[410px] z-10 bg-white border-0 border-r border-solid border-[#E5ECF3]"
                      >
                        <SummaryContainer
                          headerText="Insights"
                          headerClassName="text-sm pt-4 pb-2 pl-5 flex justify-between items-center"
                          hasFilters
                        />
                      </div>
                      <div
                        style={{
                          height: headerHeight ? `calc(100vh - ${headerHeight + 76}px)` : undefined,
                          width: headerWidth,
                        }}
                        className="fixed pl-[410px] h-screen w-full flex flex-col flex-1"
                      >
                        <FeedbackContainer className="grow pb-11" headerPagination />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            }
          </div>
        </div>
      </main>
      <ReleaseCreateModal
        visible={type === 'annotation_create'}
        topicId={topicId}
        subtopicId={subtopicId}
        name={topic.name}
        {...payload}
      />
    </FiltersProvider>
  );
}
