import React, {
  useCallback, useEffect, useMemo,
  useState,
} from 'react';
import {
  Button, message, Modal, Select,
} from 'antd';
import { ArrowRightCircle } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { taxonomy } from '@/api';
import useTaxonomy from '../../hooks/useTaxonomy';
import { useTaxonomyTableStore } from '../../store/useStore';

const TaxonomyMoveModal = ({
  setVisible,
  visible,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { selectedTopics, resetSelected } = useTaxonomyTableStore();
  const {
    data, isLoading, error, isError,
  } = useTaxonomy();

  const [categoryId, setCategoryId] = useState();

  const preparedData = useMemo(
    () => ({
      topic_ids: selectedTopics.map((topic) => topic.id),
      category_id: categoryId,
    }),
    [categoryId, selectedTopics],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const moveTaxonomy = useMutation({
    mutationKey: ['moveTaxonomy'],
    mutationFn: () => taxonomy.move(preparedData),
    onSuccess: () => {
      resetSelected();

      message.success('Moved successfully');
      setVisible(false);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['taxonomy'] });
      await queryClient.invalidateQueries({ queryKey: ['topics'] });
    },
    onError: () => {
      message.error('Failed moving');
    },
  });

  const onOk = () => {
    moveTaxonomy.mutate();
  };

  useEffect(() => {
    if (isError) {
      message.error(error.message, 1.5);
    }
  }, [error]);

  return (
    <Modal
      title={(
        <>
          <ArrowRightCircle size={16} />
          {' '}
          Move segment
          {selectedTopics.length > 1 && 's'}
        </>
      )}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button className="float-left" key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={moveTaxonomy.isLoading} onClick={onOk}>
          Save
        </Button>,
      ]}
      visible={visible}
      className="taxonomy-modal"
      {...props}
    >
      <div className="category-modal-body">
        <p>
          Where do you want to move the selected items?
        </p>

        <Select
          disabled={isLoading || moveTaxonomy.isLoading}
          loading={isLoading}
          className="large_select"
          placeholder="Choose category"
          allowClear
          style={{
            width: '100%',
          }}
          initialValue={false}
          onChange={(item) => setCategoryId(item)}
          options={
            data?.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          }
        />
      </div>
    </Modal>
  );
};

export default TaxonomyMoveModal;
