import React, { useCallback, useMemo } from 'react';
import { Button, message, Modal } from 'antd';
import { Trash2Icon } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { taxonomy } from '@/api';
import { useTaxonomyTableStore } from '../../store/useStore';

const TaxonomyDeleteModal = ({ setVisible, visible, ...props }) => {
  const queryClient = useQueryClient();

  const {
    selectedCategories, selectedTopics, selectedSubtopics, resetSelected,
  } = useTaxonomyTableStore();

  const type = useMemo(() => {
    if (selectedCategories.length) {
      return `categor${selectedCategories.length > 1 ? 'ies' : 'y'}`;
    }
    if (selectedTopics.length) {
      return `segment${selectedTopics.length > 1 ? 's' : ''}`;
    }
    if (selectedSubtopics.length) {
      return `subsegment${selectedSubtopics.length > 1 ? 's' : ''}`;
    }
    return '';
  }, [selectedCategories, selectedTopics, selectedSubtopics]);

  const preparedData = useMemo(
    () => ({
      category_ids: selectedCategories.map((category) => category.id),
      topic_ids: selectedTopics.map((topic) => topic.id),
      subtopic_ids: selectedSubtopics.map((subtopic) => subtopic.id),
    }),
    [selectedCategories, selectedTopics, selectedSubtopics],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const deleteTaxonomy = useMutation({
    mutationKey: ['deleteTaxonomy'],
    mutationFn: () => taxonomy.delete(preparedData),
    onSuccess: () => {
      resetSelected();

      message.success('Deleted successfully');
      setVisible(false);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['taxonomy'] });
      queryClient.invalidateQueries({ queryKey: ['topics'] });
      queryClient.invalidateQueries({ queryKey: ['subtopics'] });
    },
    onError: () => {
      message.error('Failed to delete');
    },
  });

  const onOk = () => {
    deleteTaxonomy.mutate();
  };

  return (
    <Modal
      title={(
        <>
          <Trash2Icon size={16} />
          {' '}
          Delete
          {' '}
          {type}
        </>
      )}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button className="float-left" key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          className="delete-button"
          key="submit"
          type="danger"
          loading={deleteTaxonomy.isLoading}
          onClick={onOk}
        >
          Yes, delete
        </Button>,
      ]}
      visible={visible}
      className="taxonomy-modal"
      {...props}
    >
      <div className="category-modal-body">
        <p className="mb-0">Are you sure you want to delete the items?</p>
      </div>
    </Modal>
  );
};

export default TaxonomyDeleteModal;
