import React from 'react';
import { Spin } from 'antd';
import useTicketsCounter from '@/components/bucket/hooks/useTicketsCounter';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { calculateDifference, formatCount } from '@/components/taxonomy/utils';
import { Count } from '../../Count';

export function TicketsColumn({ record }) {
  const isCategory = 'has_children' in record;
  const isTopic = 'groups' in record;
  const isSubtopic = !isCategory && !isTopic;

  const groupId = isCategory ? record.id : null;
  const topicId = isTopic ? record.id : null;
  const subtopicId = isSubtopic ? record.id : null;

  const defaultDate = useDefaultDateStore((state) => state.defaultDate);
  const { data, isLoading } = useTicketsCounter(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    group_id: groupId,
    topic_id: topicId,
    subtopic_id: subtopicId,
    topic_summary_request_id: null,
    follow_exclusions: true,
  });

  const memoizedData = React.useMemo(() => ({
    value: formatCount(data?.value),
    trend: data?.trend,
    percent: calculateDifference(data?.value, data?.value_previous),
  }), [data]);

  if (isLoading) {
    return (
      <Spin size="small" />
    );
  }

  return (
    <Count>
      <Count.Tooltip title="The ammount of tickets in the current period">
        <Count.Title>{memoizedData.value}</Count.Title>
      </Count.Tooltip>
      <Count.Divider />
      <Count.Tooltip title="Change in tickets compared to the previous period">
        <Count.TrendAndProcent trend={memoizedData.trend} percent={memoizedData.percent} />
      </Count.Tooltip>
    </Count>
  );
}
