import axios from './axios';
import auth from './auth';

const taxonomy = {
  move: (data) => axios.post(
    '/api/taxonomy/move',
    data,
    {
      params: {
        team_id: auth.team().id,
      },
    },
  ),
  merge: (data) => axios.post(
    '/api/taxonomy/merge',
    data,
    {
      params: {
        team_id: auth.team().id,
      },
    },
  ),
  delete: (data) => axios.post(
    '/api/taxonomy/delete',
    data,
    {
      params: {
        team_id: auth.team().id,
      },
    },
  ),
};

export default taxonomy;
