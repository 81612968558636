import React from 'react';
import { Count } from '../../Count';

export function RatioColumn({ value, trend }) {
  return (
    <Count>
      <Count.Tooltip title="The ratio of feedback compared to the total in the selected period">
        <Count.Title>{value}</Count.Title>
      </Count.Tooltip>
      <Count.Trend trend={trend} />
    </Count>
  );
}
