import React from 'react';
import { Form as AntdForm, Input } from 'antd';
import PopoutForm from '../forms/PopoutForm';

const { Item } = AntdForm;

const Form = ({
  form, defaultValues, error, onSubmit,
}) => (
  <PopoutForm
    layout="vertical"
    form={form}
    popoutError={error}
    onFinish={onSubmit}
    initialValues={defaultValues}
  >
    <Item
      key="name"
      name="name"
      label="Name"
      rules={[{ required: true, message: 'Name is required' }]}
    >
      <Input
        size="large"
        placeholder="Name"
      />
    </Item>
    <Item
      key="keywords"
      name="keywords"
      label="Keywords"
      rules={[{ required: true, message: 'Keywords are required' }]}
    >
      <Input.TextArea
        size="large"
        placeholder="Keywords or phrases, one per line"
      />
    </Item>
  </PopoutForm>
);

export default Form;
