import React, { useEffect, useMemo, useState } from 'react';
import SummaryResults, { getAiVersion, getSummaryText } from '@/components/summary/SummaryResults';
import { useParams } from 'react-router-dom';
import { ListFilter } from 'lucide-react';
import { useFilters } from '@/components/filter/context';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import useURLState from '@/hooks/useUrlState';
import useSummary from '@/components/discovery/hooks/useSummary';
import { Button, message, Dropdown } from 'antd';
import TopFilters from '@/components/summary/TopFilters';
import { useSummaryVisibleTypes } from '@/store/zustand/useSummaryVisibleTypes';

const SummaryContainer = ({
  handleSeeMore, seeMoreText, disableClick, headerText, headerClassName, hasFilters,
}) => {
  const { topicId, subtopicId } = useParams();
  const isDiscovery = !topicId && !subtopicId;

  const { filters } = useFilters();
  const { filters: globalFilters, setFiltersField } = useFiltersStore();

  const [disabled, setDisabled] = useState(false);
  const { defaultDate } = useDefaultDateStore();
  const { urlQueryState } = useURLState();

  const newFilters = useMemo(() => {
    const tempFilters = isDiscovery ? globalFilters : filters;
    return ({
      topic_id: topicId,
      subtopic_id: subtopicId,
      start: defaultDate.start,
      end: defaultDate.end,
      custom_fields: tempFilters.custom_fields,
      keywords: tempFilters.included_keywords,
      sentiment: tempFilters.sentiment,
      labels: tempFilters.labels,
      exclude_labels: tempFilters.exclude_labels,
      excluded_keywords: tempFilters.excluded_keywords,
      sources: tempFilters.sources,
    });
  }, [
    // local filters
    filters.custom_fields,
    filters.included_keywords,
    filters.sentiment,
    filters.labels,
    filters.exclude_labels,
    filters.excluded_keywords,
    filters.sources,

    // global filters
    globalFilters.custom_fields,
    globalFilters.included_keywords,
    globalFilters.sentiment,
    globalFilters.labels,
    globalFilters.exclude_labels,
    globalFilters.excluded_keywords,
    globalFilters.sources,

    defaultDate.start,
    defaultDate.end,
    topicId,
    subtopicId,
  ]);

  const [visibleData, setVisibleData] = useState([]);
  const { visibleTypes, setVisibleTypes } = useSummaryVisibleTypes((state) => state);

  const {
    data: response, isError, isLoading, error,
  } = useSummary(newFilters, setFiltersField, disabled);
  const data = useMemo(() => (response ? response.results : []), [response]);
  const id = useMemo(() => (response ? response.id : null), [response]);

  const total = useMemo(() => data.reduce((val, item) => val + item.count, 0), [data]);

  const onGenerate = () => {
    setDisabled(true);
    setVisibleTypes(['issue', 'request', 'question', 'other']);
  };

  const onCopy = () => {
    navigator.clipboard.writeText(
      visibleData.reduce((val, item) => {
        const percent = Math.ceil((item.count / total) * 100);
        const text = getSummaryText(item);

        if (getAiVersion() === 'v2') {
          return `${text}`;
        }

        return `${val}\n${percent}% ${text.split(' ')[0] === 'users' ? 'of' : ''} ${text}`;
      }, ''),
    );
    message.success('Copied to clipboard');
  };

  useEffect(() => {
    if (id) {
      setFiltersField('topic_summary_request_id', id);
    }
  }, [id]);

  useEffect(() => {
    setVisibleData(data.filter((item) => visibleTypes.includes(item.intent)));
  }, [data, visibleTypes]);

  useEffect(() => {
    if (urlQueryState.insights) {
      onGenerate();
    }
  }, []);

  return (
    <>
      <span className={headerClassName}>
        <span>{headerText}</span>
        {hasFilters ? (
          <Dropdown
            trigger={['click']}
            overlay={(
              <div className="summary-filter-dropdown">
                <TopFilters className="px-5 py-4" checked={visibleTypes} setChecked={setVisibleTypes} onCopy={onCopy} />
              </div>
            )}
          >
            <Button className="summary-filter-button mr-5" type="link">
              <ListFilter size={14} />
            </Button>
          </Dropdown>
        ) : null}
      </span>
      <SummaryResults
        data={visibleData}
        isError={isError}
        isLoading={isLoading}
        error={error}
        total={total}
        handleSeeMore={handleSeeMore}
        seeMoreText={seeMoreText}
        disableClick={disableClick}
      />
    </>

  );
};

export default SummaryContainer;
