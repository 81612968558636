/* eslint-disable no-unused-vars */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  List, Pagination, Tooltip, message, Drawer, Divider, Button,
} from 'antd';
import isEqual from 'lodash/isEqual';
import useURLState from '@/hooks/useUrlState/useURLState';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import ListItem from '@/components/discovery/ListItem';
import { cn } from '@/helpers/util';
import { useParams } from 'react-router-dom';
import {
  ChevronDown, ChevronLeft, ChevronRight, ChevronUp, MessageCircleMore, MessageCircleQuestion, Lightbulb, DotIcon,
} from 'lucide-react';
import { useThreadIdStore } from '@/store/zustand/useThreadIdStore';
import { useThreadPaginationStore } from '@/store/zustand/useThreadPaginationStore';
import SummaryContainer from '@/components/bucket/SummaryContainer';
import AddFilterButton from '@/components/bucket/AddFilterButton';
import FiltersHorizontalList from '@/components/bucket/FiltersHorizontalList';
import { useSummaryVisibleTypes } from '@/store/zustand/useSummaryVisibleTypes';
import useTicketsCounter from '@/components/bucket/hooks/useTicketsCounter';
import useUsersCounter from '@/components/bucket/hooks/useUsersCounter';
import { usePaginationStore } from '@/store/zustand/usePaginationStore';
import { useConversationStore } from '@/store/zustand/useConversationsStore';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import useConversations from './hooks/useConversations';
import { ReactComponent as TriangleAlert } from './svgs/triangle-alert.svg';
import { useFilters, useFiltersDispatch } from '../filter/context';

const paginationRender = (_, type, originalElement) => {
  if (type === 'prev') {
    return <ChevronLeft size={18} strokeWidth={2} className="text-[#75899B]" />;
  }
  if (type === 'next') {
    return <ChevronRight size={18} strokeWidth={2} className="text-[#75899B]" />;
  }
  return originalElement;
};

export function ThreadFeedbackContainer({
  className,
}) {
  const { threadId } = useThreadIdStore((state) => state);
  const [conversations, setConversations] = useState([]);

  const {
    currentPage, perPage, total, setPage, setPager,
  } = useThreadPaginationStore();

  const {
    data: response, isError, isLoading, error, isFetching,
  } = useConversations({
    thread_id: threadId,
  }, {
    perPage,
    currentPage,
  });

  useEffect(() => {
    if (threadId) {
      setConversations([]);
    }
  }, [threadId]);

  useEffect(() => {
    if (!response) return;

    setConversations(response.data);
    setPager(response.pager);
  }, [response]);

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <section className={cn('group/button bg-white relative rounded-lg overflow-hidden', className)}>
      <header
        className="mb-2 flex pr-1 items-center justify-between"
      >
        <span className="flex text-sm pt-4 pb-1 pl-5 gap-2 items-center" />
        <aside className="flex items-center space-x-4 pt-4 pl-5">
          <span className="text-sm text-[#75899B] font-[Gordita-Regular]">
            {perPage * (currentPage - 1) + 1}
            -
            {currentPage * perPage}
            {' '}
            of
            {' '}
            {total}
          </span>
          <Pagination
            itemRender={paginationRender}
            disabled={isLoading}
            className="!text-sm !text-[#75899B] [&>*]:items-center [&>*]:!inline-flex font-[Gordita-Regular] [&_.ant-pagination-simple-pager]:!hidden [&_.ant-pagination-prev]:!mr-0 [&_.ant-pagination-disabled>svg]:!text-[#DFE8F1]"
            simple
            current={currentPage}
            pageSize={perPage}
            total={total}
            onChange={handlePageChange}
          />
        </aside>
      </header>
      <List
        className="h-[calc(100%-40px)] overflow-y-scroll"
        loading={isLoading || isFetching}
        dataSource={conversations}
        renderItem={ListItem}
      />
      <footer
        style={{ height: '32px' }}
        className="mb-2 flex border-0 border-t border-[#DFE9F1] pt-2 pr-1 border-solid items-center justify-end"
      >
        <aside className="flex items-center space-x-4">
          <span className="text-sm text-[#75899B] font-[Gordita-Regular]">
            {perPage * (currentPage - 1) + 1}
            -
            {currentPage * perPage}
            {' '}
            of
            {' '}
            {total}
          </span>
          <Pagination
            itemRender={paginationRender}
            disabled={isLoading}
            className="!text-sm !text-[#75899B] [&>*]:items-center [&>*]:!inline-flex font-[Gordita-Regular] [&_.ant-pagination-simple-pager]:!hidden [&_.ant-pagination-prev]:!mr-0 [&_.ant-pagination-disabled>svg]:!text-[#DFE8F1]"
            simple
            current={currentPage}
            pageSize={perPage}
            total={total}
            onChange={handlePageChange}
          />
        </aside>
      </footer>
    </section>
  );
}

export function FeedbackContainer({
  className, collapsed, setCollapsed = null, headerPagination = false, followExclusions = false,
}) {
  const { topicId, subtopicId } = useParams();
  const {
    urlQueryState, setUrlQueryParam, deleteUrlQueryParam, disableUrlQueryState,
  } = useURLState();
  const { threadId, setThreadId } = useThreadIdStore((state) => state);

  const {
    conversations, setConversations,
  } = useConversationStore();
  const {
    currentPage, perPage, total, setPage, setPager,
  } = usePaginationStore();

  const { defaultDate } = useDefaultDateStore();
  const { filters } = useFilters();
  const { included_keywords: includedKeywords, ...restFilters } = filters;
  const dispatch = useFiltersDispatch();

  const { data: ticketsCount } = useTicketsCounter(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    topic_id: topicId,
    subtopic_id: subtopicId,
    ...restFilters,
    keywords: includedKeywords,
    topic_summary_request_id: null,
    follow_exclusions: true,
  });

  const { data: usersCount } = useUsersCounter(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
    topic_id: topicId,
    subtopic_id: subtopicId,
    ...restFilters,
    keywords: includedKeywords,
    topic_summary_request_id: null,
    follow_exclusions: true,
  });

  const {
    data: response, isError, isLoading, error, isFetching,
  } = useConversations({
    start: defaultDate.start,
    end: defaultDate.end,
    topic_id: topicId,
    subtopic_id: subtopicId,
    ...restFilters,
    keywords: includedKeywords,
    topic_summary_request_id: null,
    follow_exclusions: followExclusions,
  }, {
    perPage,
    currentPage,
  });

  useEffect(() => {
    if (!response) return;

    setConversations(response.data);
    setPager(response.pager);
  }, [response]);

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  const handlePageChange = (page) => {
    setPage(page);
    if (!disableUrlQueryState) {
      if (page === 1) {
        deleteUrlQueryParam('page');
      } else {
        setUrlQueryParam('page', page);
      }
    }
  };

  useEffect(() => {
    if (!disableUrlQueryState) {
      setPage(urlQueryState.page ? urlQueryState.page : 1);
    }
  }, [urlQueryState.page]);

  useEffect(() => {
    if (!urlQueryState.sentiment) return;
    // TODO: validate better filters
    if (urlQueryState.sentiment !== 'negative' && urlQueryState.sentiment !== 'positive') return;

    dispatch({ type: 'SET_FILTERS_FIELD', field: 'sentiment', value: [urlQueryState.sentiment] });
  }, [urlQueryState.sentiment]);

  return (
    <section className={cn('group/button bg-white relative rounded-lg overflow-hidden', className)}>
      {setCollapsed && (
        <div className="absolute w-full translate-x-1/2 z-10">
          <Tooltip className="opacity-0 group-hover/button:opacity-100 transition-opacity" title={`${!collapsed ? 'Expand' : 'Collapse'} feedback`}>
            <button
              type="button"
              className=" w-8 justify-center bg-[#DFE9F1] rounded-3xl cursor-pointer p-0 border-none flex absolute top-[18px] -left-[16px]"
              onClick={() => setCollapsed((prev) => !prev)}
            >
              {!collapsed ? (
                <ChevronUp size={16} />
              ) : (
                <ChevronDown size={16} />
              )}
            </button>
          </Tooltip>
        </div>
      )}
      {
        headerPagination ? (
          <header
            className="mb-2 flex pr-1 items-center justify-between"
          >
            <span className="flex text-sm pt-4 pb-1 pl-5 gap-2 items-center">
              <span>Feedback</span>
              <span className="text-xs">{total ? `(${total})` : ''}</span>
              {ticketsCount?.value > 0 && (
                <>
                  <DotIcon size={16} />
                  <span>Tickets</span>
                  <span className="text-xs">{ticketsCount.value ? `(${ticketsCount.value})` : ''}</span>
                </>
              )}
              {usersCount > 0 && (
                <>
                  <DotIcon size={16} />
                  <span>People</span>
                  <span className="text-xs">{usersCount ? `(${usersCount})` : ''}</span>
                </>
              )}
            </span>
            <aside className="flex items-center space-x-4 pt-4 pl-5">
              <span className="text-sm text-[#75899B] font-[Gordita-Regular]">
                {perPage * (currentPage - 1) + 1}
                -
                {currentPage * perPage}
                {' '}
                of
                {' '}
                {total}
              </span>
              <Pagination
                itemRender={paginationRender}
                disabled={isLoading}
                className="!text-sm !text-[#75899B] [&>*]:items-center [&>*]:!inline-flex font-[Gordita-Regular] [&_.ant-pagination-simple-pager]:!hidden [&_.ant-pagination-prev]:!mr-0 [&_.ant-pagination-disabled>svg]:!text-[#DFE8F1]"
                simple
                current={currentPage}
                pageSize={perPage}
                total={total}
                onChange={handlePageChange}
              />
            </aside>
          </header>
        ) : null
      }
      <List
        className="h-[calc(100%-40px)] overflow-y-scroll"
        loading={isLoading || isFetching}
        dataSource={conversations}
        renderItem={ListItem}
      />
      <footer
        style={{ height: '32px' }}
        className="mb-2 flex border-0 border-t border-[#DFE9F1] pt-2 pr-1 border-solid items-center justify-end"
      >
        <aside className="flex items-center space-x-4">
          <span className="text-sm text-[#75899B] font-[Gordita-Regular]">
            {perPage * (currentPage - 1) + 1}
            -
            {currentPage * perPage}
            {' '}
            of
            {' '}
            {total}
          </span>
          <Pagination
            itemRender={paginationRender}
            disabled={isLoading}
            className="!text-sm !text-[#75899B] [&>*]:items-center [&>*]:!inline-flex font-[Gordita-Regular] [&_.ant-pagination-simple-pager]:!hidden [&_.ant-pagination-prev]:!mr-0 [&_.ant-pagination-disabled>svg]:!text-[#DFE8F1]"
            simple
            current={currentPage}
            pageSize={perPage}
            total={total}
            onChange={handlePageChange}
          />
        </aside>
      </footer>
      <Drawer className="feedback-drawer" visible={threadId} onClose={() => setThreadId(null)} title="Feedback" placement="right">
        <ThreadFeedbackContainer />
      </Drawer>
    </section>
  );
}

export default function DiscoveryContainer() {
  const { filters } = useFilters();
  const { filters: globalFilters, setFilters } = useFiltersStore();
  const { visibleTypes, setVisibleTypes } = useSummaryVisibleTypes((state) => state);
  const headerRef = useRef();
  const parentHeaderRef = useRef();
  const [headerPadding, setHeaderPadding] = useState(0);
  const [headerWidth, setHeaderWidth] = useState();
  const [headerHeight, setHeaderHeight] = useState();

  useEffect(() => {
    if (!isEqual(filters, globalFilters)) setFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderPadding(headerRef.current.offsetHeight);
      const resizeObserver = new ResizeObserver((entries) => setHeaderPadding(entries[0].target.offsetHeight));
      resizeObserver.observe(headerRef.current);
    }
    if (parentHeaderRef && parentHeaderRef.current) {
      setHeaderWidth(parentHeaderRef.current.offsetWidth);
      setHeaderHeight(parentHeaderRef.current.offsetHeight);
      const resizeObserver = new ResizeObserver((entries) => {
        setHeaderWidth(entries[0].target.offsetWidth);
        setHeaderHeight(entries[0].target.offsetHeight);
      });
      resizeObserver.observe(parentHeaderRef.current);
    }
  }, []);

  return (
    <main className="flex gap-1.5">
      <div ref={parentHeaderRef} className="flex flex-col w-full">
        <div ref={headerRef} className="fixed bg-[#F6F9FB] z-10" style={{ width: headerWidth }}>
          <div className="flex bucket-top-row">
            <AddFilterButton />
            <Divider type="vertical" />
            <Button
              onClick={() => setVisibleTypes(['issue', 'request', 'question', 'other'])}
              className={cn('bucket-btn', {
                selected: visibleTypes.length === 4,
              })}
              type="secondary"
            >
              <span className="anticon">
                <MessageCircleMore size={16} />
              </span>
              All insights
            </Button>
            <Button
              onClick={() => setVisibleTypes(['issue'])}
              className={cn('bucket-btn', {
                selected: visibleTypes.length === 1 && visibleTypes[0] === 'issue',
              })}
              type="secondary"
            >
              <span className="anticon">
                <TriangleAlert />
              </span>
              Issues
            </Button>
            <Button
              onClick={() => setVisibleTypes(['question'])}
              className={cn('bucket-btn', {
                selected: visibleTypes.length === 1 && visibleTypes[0] === 'question',
              })}
              type="secondary"
            >
              <span className="anticon">
                <MessageCircleQuestion size={16} />
              </span>
              Questions
            </Button>
            <Button
              onClick={() => setVisibleTypes(['request'])}
              className={cn('bucket-btn', {
                selected: visibleTypes.length === 1 && visibleTypes[0] === 'request',
              })}
              type="secondary"
            >
              <span className="anticon">
                <Lightbulb size={16} />
              </span>
              Requests
            </Button>
          </div>
          <FiltersHorizontalList />
        </div>
        <div style={{ paddingTop: headerPadding }}>
          <div className="flex rounded-lg font-[Gordita-Regular] text-[#44596C]">
            <div className="flex flex-col w-full">
              <div className="flex bg-white">
                <div
                  style={{
                    height: headerHeight ? `calc(100vh - ${headerHeight + 76}px)` : undefined,
                  }}
                  className="fixed flex flex-col h-screen w-[410px] z-10 bg-white border-0 border-r border-solid border-[#E5ECF3]"
                >
                  <SummaryContainer
                    headerText="Insights"
                    headerClassName="text-sm pt-4 pb-2 pl-5 flex justify-between items-center"
                    hasFilters
                  />
                </div>
                <div
                  style={{
                    height: headerHeight ? `calc(100vh - ${headerHeight + 76}px)` : undefined,
                    width: headerWidth,
                  }}
                  className="fixed pl-[410px] h-screen w-full flex flex-col flex-1"
                >
                  <FeedbackContainer className="grow pb-11" headerPagination followExclusions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
