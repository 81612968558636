import React, { createContext, useRef } from 'react';
import createTaxonomyTableStore from './create-store';

export const TaxonomyTableStoreContext = createContext(undefined);

export function TaxonomyTableProvider({ children, ...storeProps }) {
  const storeRef = useRef();

  if (!storeRef.current) {
    storeRef.current = createTaxonomyTableStore(storeProps);
  }

  return (
    <TaxonomyTableStoreContext.Provider value={storeRef.current}>
      {children}
    </TaxonomyTableStoreContext.Provider>
  );
}
