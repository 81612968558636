import { Button, Drawer, Tag } from 'antd';
import React, { useMemo, useState } from 'react';
import './EditTaxonomyDrawer.less';
import {
  ArrowRightCircle,
  MergeIcon,
  Trash2Icon,
} from 'lucide-react';
import TaxonomyDeleteModal from '@/components/taxonomy/components/EditTaxonomyDrawer/TaxonomyDeleteModal';
import TaxonomyMoveModal from '@/components/taxonomy/components/EditTaxonomyDrawer/TaxonomyMoveModal';
import TaxonomyDeleteCategoryModal
  from '@/components/taxonomy/components/EditTaxonomyDrawer/TaxonomyDeleteCategoryModal';
import { useHistory } from 'react-router-dom';
import TaxonomyTable from '../Table';
import { getColumns } from '../Table/Table.columns';
import { useTaxonomyTableStore } from '../../store/useStore';
import useTaxonomy from '../../hooks/useTaxonomy';
import TaxonomyMergeModal from './TaxonomyMergeModal';
import { areSubtopicsInDifferentTopics } from '../../utils';

const EditTaxonomyDrawer = ({ openDrawer, setOpenDrawer }) => {
  const history = useHistory();
  const {
    data: tableData, selectedCategories, selectedSubtopics, selectedTopics, addSelected, removeSelected, resetSelected,
  } = useTaxonomyTableStore();

  const { data, isLoading } = useTaxonomy();

  const [isMoveVisible, setIsMoveVisible] = useState(false);
  const [isMergeVisible, setIsMergeVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDeleteCategoryVisible, setIsDeleteCategoryVisible] = useState(false);

  // const searchProps = {
  //   className: 'taxonomy__drawer__search',
  //   placeholder: 'Search',
  //   prefix: <SearchOutlined />,
  //   onChange: debounce((e) => {
  //     dispatch({ type: SET_SEARCH_FILTER_ACTION, payload: e.target.value });
  //   }, 400),
  // };

  const columns = useMemo(() => {
    const locCols = getColumns(history).slice(0, 1);
    locCols[0].width = '100%';
    return locCols;
  }, []);

  /** @type {import('antd/lib/table/interface').TableRowSelection} */
  const rowSelection = {
    selectedRowKeys: [
      ...selectedCategories.map((category) => category.id),
      ...selectedTopics.map((topic) => topic.id),
      ...selectedSubtopics.map((subtopic) => subtopic.id),
    ],
    onSelect: (record, selected) => {
      const isCategory = 'has_children' in record;
      const isTopic = 'has_subtopics' in record;

      // eslint-disable-next-line no-nested-ternary
      const type = isCategory ? 'categories' : isTopic ? 'topics' : 'subtopics';

      if (selected) {
        addSelected(record, type);
        return;
      }

      removeSelected(record, type);
    },
    getCheckboxProps: () => ({
      className: '[&_.ant-checkbox-checked::after]:rounded-none [&_.ant-checkbox-inner]:rounded-none',
      // disabled: listDisabled || item.disabled,
    }),
  };

  const handleDelete = () => {
    if (selectedCategories.length > 0 && selectedCategories.some((category) => category.has_children)) {
      // message.error('Cannot delete a category with topics');
      setIsDeleteCategoryVisible(true);
      return;
    }
    setIsDeleteVisible(true);
  };

  const memoizedMergeDisabled = useMemo(() => areSubtopicsInDifferentTopics(tableData, selectedSubtopics), [tableData, selectedSubtopics]);

  return (
    <>
      <Drawer
        className="taxonomy-drawer [&_.ant-drawer-body]:no-scrollbar"
        title={
          (
            <div className="flex items-center gap-3">
              <span>Edit Taxonomy</span>
              <Tag color="red">BETA</Tag>
            </div>
          )
        }
        width={662}
        destroyOnClose
        placement="right"
        onClose={() => {
          setOpenDrawer(false);
          resetSelected();
        }}
        visible={openDrawer}
        // footer={(
        //   <div className="flex items-center justify-between">
        //     <Button
        //       className=" !flex font-[Gordita-Regular] gap-1 items-center text-bold"
        //       onClick={() => setOpenDrawer(false)}
        //       type="secondary"
        //     >
        //       Cancel
        //     </Button>
        //     <Button className="" onClick={handleSaveTaxonomy} type="primary">
        //       Save Taxonomy
        //     </Button>
        //   </div>
        // )}
      >
        <div className="taxonomy-header sticky top-0 z-10 bg-[#F6F9FB] flex justify-between items-center pb-3.5">
          <div className="flex items-center gap-4">
            {/* <Input {...searchProps} /> */}
          </div>

          <div className="flex gap-2">
            <Button
              className="!flex font-[Gordita-Regular] gap-1 items-center disabled:opacity-50"
              type="secondary"
              onClick={() => setIsMoveVisible(true)}
              disabled={
                selectedCategories.length + selectedTopics.length + selectedSubtopics.length === 0
                || (selectedCategories.length > 0 || selectedSubtopics.length > 0)
              }
            >
              <ArrowRightCircle size={16} />
              Move
            </Button>

            <Button
              className="!flex font-[Gordita-Regular] gap-1 items-center disabled:opacity-50"
              type="secondary"
              onClick={() => setIsMergeVisible(true)}
              disabled={
                (selectedCategories.length + selectedTopics.length + selectedSubtopics.length === 0)
                || memoizedMergeDisabled
                || (selectedTopics.length > 0 && selectedCategories.length > 0)
                || (selectedSubtopics.length > 0 && selectedCategories.length > 0)
                || (selectedSubtopics.length < 2 && selectedTopics.length < 2 && selectedCategories.length < 2)
              }
            >
              <MergeIcon size={16} />
              Merge
            </Button>

            <Button
              className="!flex font-[Gordita-Regular] gap-1 items-center disabled:opacity-50"
              type="secondary"
              onClick={handleDelete}
              disabled={selectedCategories.length + selectedTopics.length + selectedSubtopics.length === 0}
            >
              <Trash2Icon size={16} />
              Delete
            </Button>
          </div>
        </div>
        <TaxonomyTable
          showHeader={false}
          columns={columns}
          loading={isLoading}
          dataSource={data}
          rowSelection={rowSelection}
        />
      </Drawer>

      <TaxonomyMoveModal
        setVisible={setIsMoveVisible}
        visible={isMoveVisible}
      />
      <TaxonomyMergeModal
        setVisible={setIsMergeVisible}
        visible={isMergeVisible}
      />
      <TaxonomyDeleteModal
        setVisible={setIsDeleteVisible}
        visible={isDeleteVisible}
      />
      <TaxonomyDeleteCategoryModal
        setVisible={setIsDeleteCategoryVisible}
        visible={isDeleteCategoryVisible}
      />
    </>
  );
};

export default EditTaxonomyDrawer;
