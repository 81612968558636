import { topics } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { transformCustomFieldToGet } from '@/api/conversations';

export default function useUsersCounter(id, filters) {
  let newFilters = filters;
  if (filters.custom_fields) {
    newFilters = {
      ...filters,
      ...transformCustomFieldToGet(filters.custom_fields),
    };
  }
  if (filters.sources) {
    newFilters = {
      ...newFilters,
      sources: JSON.stringify(filters.sources),
    };
  }
  return useQuery({
    queryKey: ['usersCount', id, { newFilters }],
    queryFn: async () => {
      const { data } = await topics.getUsersCount(id, newFilters);
      return data;
    },
    refetchOnWindowFocus: !!newFilters,
  });
}
