export function formatCount(count) {
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K`;
  }
  return count;
}

export function calculateDifference(value, previousValue) {
  const count = Math.abs(previousValue - value);
  const procent = (count / previousValue) * 100;
  if (procent > 100) return 100;
  if (Number.isNaN(procent)) return 0;
  return procent.toFixed(0);
}

export function areSubtopicsInDifferentTopics(data, selectedSubtopics) {
  const selectedSubtopicIds = selectedSubtopics.map((subtopic) => subtopic.id);
  const topicSet = new Set();

  data.forEach((category) => {
    category.children?.forEach((topic) => {
      topic.children?.forEach((subtopic) => {
        if (selectedSubtopicIds.includes(subtopic.id)) {
          topicSet.add(topic.id);
        }
      });
    });
  });

  return topicSet.size > 1;
}
