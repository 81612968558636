import { useQuery } from '@tanstack/react-query';
import { dashboard } from '@/api';

export function useTrendBreakdown({
  start, end, onlyFavorites,
}) {
  return useQuery({
    queryKey: ['trend-breakdown', {
      start,
      end,
      onlyFavorites,
    }],
    queryFn: async () => {
      const { data } = await dashboard.trendBreakdown(
        {
          start,
          end,
          onlyFavorites,
        },
      );
      return data;
    },
  });
}
