import React, {
  useCallback, useMemo,
  useState,
} from 'react';
import {
  Button, Input, message, Modal,
} from 'antd';
import { MergeIcon } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { taxonomy } from '@/api';
import debounce from 'lodash/debounce';
import { useTaxonomyTableStore } from '../../store/useStore';

const TaxonomyMergeModal = ({
  setVisible,
  visible,
  ...props
}) => {
  const queryClient = useQueryClient();
  const {
    selectedCategories, selectedTopics, selectedSubtopics, resetSelected,
  } = useTaxonomyTableStore();

  const type = useMemo(() => {
    if (selectedCategories.length) {
      return `categor${selectedCategories.length > 1 ? 'ies' : 'y'}`;
    }
    if (selectedTopics.length) {
      return `segment${selectedTopics.length > 1 ? 's' : ''}`;
    }
    if (selectedSubtopics.length) {
      return `subsegment${selectedSubtopics.length > 1 ? 's' : ''}`;
    }
    return '';
  }, [selectedCategories, selectedTopics, selectedSubtopics]);

  const [mergeName, setMergeName] = useState('');
  const preparedData = useMemo(
    () => ({
      merge_name: mergeName,
      ...selectedCategories.length ? { category_ids: selectedCategories.map((category) => category.id) } : {},
      ...selectedTopics.length ? { topic_ids: selectedTopics.map((topic) => topic.id) } : {},
      ...selectedSubtopics.length ? { subtopic_ids: selectedSubtopics.map((subtopic) => subtopic.id) } : {},
    }),
    [selectedCategories, selectedTopics, selectedSubtopics, mergeName],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const mergeTaxonomy = useMutation({
    mutationKey: ['mergeTaxonomy'],
    mutationFn: () => taxonomy.merge(preparedData),
    onSuccess: () => {
      resetSelected();

      message.success('Merged successfully');
      setVisible(false);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['taxonomy'] });
      await queryClient.invalidateQueries({ queryKey: ['topics'] });
      await queryClient.invalidateQueries({ queryKey: ['subtopics'] });
    },
    onError: () => {
      message.error('Failed to merge');
    },
  });

  const onOk = () => {
    mergeTaxonomy.mutate();
  };

  return (
    <Modal
      title={(
        <>
          <MergeIcon size={16} />
          {' '}
          Merge
        </>
      )}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button className="float-left" key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={mergeTaxonomy.isLoading} onClick={onOk}>
          Save
        </Button>,
      ]}
      visible={visible}
      className="taxonomy-modal"
      {...props}
    >
      <div className="category-modal-body">
        <p>
          Enter a name for the merged
          {' '}
          {type}
          ?
        </p>

        <Input
          disabled={mergeTaxonomy.isLoading}
          placeholder="Enter the new name"
          allowClear
          style={{
            width: '100%',
          }}
          defaultValue={mergeName}
          onChange={debounce((e) => setMergeName(e.target.value), 500)}
        />
      </div>
    </Modal>
  );
};

export default TaxonomyMergeModal;
