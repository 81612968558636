import React from 'react';
import { Count } from '../../Count';

export function MRRColumn({ value, trend }) {
  return (
    <Count>
      <Count.Tooltip title="Monthly recurring revenue from users talking about this painpoint">
        <Count.Title>{value}</Count.Title>
      </Count.Tooltip>
      <Count.Trend trend={trend} />
    </Count>
  );
}
