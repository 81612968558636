export const trendPeriods = [
  {
    name: '7D',
    period1: 'Last 7D',
    period2: '7-14D ago',
    period3: '14-21D ago',
  },
  {
    name: '30D',
    period1: 'Last 30D',
    period2: '30-60D ago',
    period3: '60-90D ago',
  },
  {
    name: '3M',
    period1: 'Last 3M',
    period2: '3-6M ago',
    period3: '6-9M ago',
  },
  {
    name: '6M',
    period1: 'Last 6M',
    period2: '6-12M ago',
    period3: '12-18M ago',
  },
];

export function getGraphTotal(topicIds, topic) {
  if (!topic) return 0;

  return topicIds.length > 0 ? (
    parseInt(topic.negative_count, 10)
      + parseInt(topic.positive_count, 10)
      + parseInt(topic.neutral_count, 10)
  ) : 0;
}
